<template>
  <section class="container pb-8 ">
    <div class="row">
      <!-- <div class="col-12 col-md-6 col-lg-8 mb-3">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            v-bind:class="['btn', CardOrListType === '1' ? 'btn-primary' : 'btn-light']"
            @click="CardOrList('1')"
          >
            <i class="fa fa-th-large mr-2" aria-hidden="true"></i>圖示
          </button>
          <button
            type="button"
            v-bind:class="['btn', CardOrListType === '2' ? 'btn-primary' : 'btn-light']"
            @click="CardOrList('2')"
          >
            <i class="fa fa-list mr-2" aria-hidden="true"></i>清單
          </button>
        </div>
      </div> -->
      <div class="col-12 col-md-6 col-lg-4 mb-3">
        <div class="input-group float-right">
          <input
            type="text"
            class="form-control"
            v-model="Args.keyWord"
            placeholder="科目查詢"
            aria-label=""
            aria-describedby="basic-addon1"
            @keydown.enter="Search(0)"
          />
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary" type="button" @click="Search">
              <i class="fa fa-search text-muted" aria-hidden="true"></i>
            </button>
            <button class="btn btn-outline-secondary" type="button" @click="Search(1)">
              清除
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="error-bg mb-3 mb-md-8" v-if="PageInfo.data.length === 0">
      <div>查無上課資料</div>
    </div>

    <ul class="mb-3" v-else>
      <li
        v-for="(item, index) in PageInfo.data"
        :key="index"
        class="exam-practice"
        :class="{'exam-practice--hasImage': CardOrListType === '1'}"
      >
        <div
          v-if="CardOrListType === '1'"
          class="exam-practice__image"
        >
          <div
            class="bg-image rounded-lg"
            :style="{ backgroundImage: 'url(' + item.mainImagePath + ')' }"
          ></div>
        </div>

        <div class="exam-practice__title">
          <h5>{{ item.subjectName }}</h5>
          <p class="exam-practice__progress">
            <i class="fa fa-calendar mr-2" aria-hidden="true"></i>練習期限：
            {{ item.courseViewStartDate | toTaipei | moment('YYYY-MM-DD') }} -
            {{ item.courseViewEndDate | toTaipei | moment('YYYY-MM-DD') }}
          </p>
        </div>
        <div class="exam-practice__btnbox">
          <template v-if="item.hasLook">
            <router-link
              class="btn btn-primary exam-practice__btn"
              :to="`/practices/${item.subjectId}`"
            >
              練習去
            </router-link>

            <router-link
              class="btn btn-primary exam-practice__btn"
              :to="`/practices/exam/${item.subjectId}`"
            >
              考試去
            </router-link>
          </template>

          <a v-else class="btn btn-secondary  exam-practice__btn"> 尚未開放 </a>
        </div>
      </li>
    </ul>

    <!-- 分頁 -->
    <Pagination
      @GetList="GetList()"
      :page.sync="Pagination.pageIndex"
      :page-row="Pagination.pageSize"
      :total-count="PageInfo.count"
    />
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { GetGotoPractice } from '@/api/BuyPracticeApi';

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      Args: { keyWord: '' },
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          keyWord: '',
        },
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
      CardOrListType: '2', // 設定在哪種清單模式 1圖示(好看) 2清單(醜醜)
    };
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  async activated() {
    // const myStorage = window.localStorage;
    // this.CardOrListType = myStorage.getItem('PracticesListType') !== null
    //   ? myStorage.getItem('PracticesListType')
    //   : '1';
    if (!this.$route.meta.isBack) {
      // 不是從指定頁面返回的話，進行初始化設定
      this.Args = { keyWord: '' };
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          keyWord: '',
        },
      };
      this.PageInfo = {
        count: 0, // api給的總筆數
        data: [],
      };
    }
    // 然後再針對頁面進行初始化資料存取
    this.GetList();
  },
  methods: {
    Search(isClear = 0) {
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          keyWord: '',
        },
      };
      if (isClear === 0) this.Pagination.data = { ...this.Args };
      if (isClear === 1) this.Args = { keyWord: '' };
      this.GetList();
    },
    GetList() {
      this.Pagination.data = { ...this.Args };
      GetGotoPractice(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;
          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
        }
      });
    },
    // CardOrList(type) {
    //   this.CardOrListType = type;
    //   const myStorage = window.localStorage;
    //   myStorage.setItem('PracticesListType', type);
    // },
  },
};
</script>

<style></style>
